<template>
  <v-container>
    <v-alert
      v-model="error"
      transition="fade-transition"
      color="error"
      style="z-index: 2; position: fixed; right: 15px"
    >
      {{ errorMessage }}
      <v-icon class="ml-5" @click="error = false">mdi-close</v-icon>
    </v-alert>

    <v-row justify="center">
      <v-col xs="12" xl="7">
        <h2>All Customers List</h2>
        <p class="mb-10 grey--text">
          To view more details of the customer, such as their contact
          information, properties, and deals, you may click on the row. This
          will open a detailed view, providing you with a comprehensive
          understanding of the customer's profile and interactions with our
          services.
        </p>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          class="mb-5"
          single-line
          hide-details
          clearable
        />

        <v-data-table
          :headers="customers_header"
          :items="searchableCustomers"
          :loading="loading"
          loading-text="Please wait..."
          :no-data-text="error ? errorMessage : 'No customers found.'"
          :no-results-text="error ? errorMessage : 'No customers found.'"
          :items-per-page="-1"
          :search="search"
          @click:row="view_customer"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:item.cell_phone_number="{ item }">
            <span>
              {{
                item.contact_infos
                  .filter((el) => el.type === "Cell Phone Number")
                  .map((el) => el.number)
                  .join(", ")
              }}
            </span>
          </template>

          <template v-slot:item.email="{ item }">
            <span>
              {{
                item.contact_infos
                  .filter((el) => el.type === "Email")
                  .map((el) => el.email)
                  .join(", ")
              }}
            </span>
          </template>

          <template v-slot:item.archived="{ item }">
            <span>{{ item.archived ? "Archived" : "Active" }}</span>
          </template>

          <template v-slot:item.createdAt="{ item }">
            <span>{{ item.createdAt | formatDate }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import filt from "@/plugins/filters";

export default {
  data() {
    return {
      loading: true,
      error: null,
      errorMessage: "",
      search: "",

      customers_header: [
        {
          text: "First Name",
          value: "first_name",
        },

        {
          text: "Last Name",
          value: "last_name",
        },

        {
          text: "Email",
          value: "email",
        },

        {
          text: "Contact Number",
          value: "cell_phone_number",
        },

        {
          text: "Status",
          value: "archived",
        },

        {
          text: "Created At",
          value: "createdAt",
        },
      ],

      customers: [],
    };
  },

  methods: {
    view_customer(value) {
      this.$router.push(`/dashboard/admin/customer/read/${value.id}`);
    },

    async getData() {
      try {
        this.loading = true;

        // Get all customers
        const response = await API().get(
          `api/internal-admin/customer/read_all_customers`
        );

        // console.log(response);

        this.customers = response.data; // Set customers to response data

        this.loading = false;
      } catch (error) {
        console.log(error.response.data.message);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },
  },

  filters: {
    ...filt,
  },

  computed: {
    searchableCustomers() {
      return this.customers.map((customer) => ({
        ...customer,
        cell_phone_number: customer.contact_infos
          .filter((el) => el.type === "Cell Phone Number")
          .map((el) => el.number)
          .join(", "),
        email: customer.contact_infos
          .filter((el) => el.type === "Email")
          .map((el) => el.email)
          .join(", "),
      }));
    },
  },

  created() {
    this.getData();
  },
};
</script>
